<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class=" fill-height">
      <v-card-title v-if="action === ''" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="green"
            icon="mdi-plus"
            type="label-icon"
            label="Add New"
            @on-click="add()"
          />

          <o-button
            :busy="busy"
            color="warning"
            icon="mdi-filter"
            type="label-icon"
            label="Filter"
            @on-click="showFilter = true"            
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />

          <o-drawer
            v-if="!busy"
            :auto-close="true"
            :actions="[
              {
                key: 'on-item-reset',
                label: 'RESET',
                icon: 'mdi-refresh',
                color: 'warning',
              },
              {
                key: 'on-item-search',
                label: 'SEARCH',
                icon: 'mdi-text-box-search-outline',
                color: 'success',
              },
            ]"
            button-icon="mdi-filter-plus-outline"
            :title="`FILTERS`"
            :use-button="false"
            width="25vw"
            :visible="showFilter"
            @on-item-search="
              showFilter = false;
              reset();
            "
            @on-item-reset="
              showFilter = false;
              reset(true);
            "
            @on-close="showFilter = false"
          >
            <v-row class="pa-0 ma-1">
              <v-col cols="12" class="pa-1 ma-0">
                <o-input
                  v-model="parameters.advertiser_id"
                  :busy="busy"
                  label="Advertiser ID"
                  :hasClear="true"
                />
              </v-col>
              <v-col cols="12" class="pa-1 ma-0">
                <o-input
                  v-model="parameters.advertiser_name"
                  :busy="busy"
                  label="Name"
                  :hasClear="true"
                />
              </v-col> 
              <v-col cols="12" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.active"
                  :busy="busy"
                  label="Active"
                  :items="booleans"
                  :hasId="false"
                  :multiple="true"
                />
              </v-col>
              <v-col cols="12" class="pa-1 ma-0">
                <o-combo-box
                  v-model="parameters.account_id"
                  :busy="busy"
                  label="Account"
                  :items="accounts"
                  :multiple="true" 
                />
              </v-col> 
            </v-row>
          </o-drawer>
        </div>
      </v-card-title>
      <v-divider v-if="action === ''" />

      <v-card-text v-if="action === ''" class="text-primary">
        <o-table
          ref="table"
          type="rest"
          :lazy="true"
          :busy="busy"
          :has-paging="true"
          :height="getHeight(442)"
          :fixed-header="true"
          method="POST"
          :rest="{
            url: `/rest/intranet/list`,
            payload: listParameters,
          }"
          :headers="[
            {
              text: '#',
              align: 'start',
              sortable: false,
              value: 'row_no',
              type: 'text',
            },
            {
              text: 'Account',
              align: 'start',
              sortable: false,
              value: 'account_name',
              type: 'slot',
            },
            {
              text: 'Name',
              align: 'start',
              sortable: false,
              value: 'advertiser_name',
              type: 'slot',
            },
            {
              text: 'Category',
              align: 'start',
              sortable: false,
              value: 'advertiser_category',
              type: 'text',
            },
            {
              text: 'Default Click URL',
              align: 'start',
              sortable: false,
              value: 'default_click_url',
              type: 'text',
            },
            {
              text: 'Active',
              align: 'start',
              value: 'active',
              type: 'boolean',
            },
            {
              text: 'Created Date',
              align: 'start',
              value: 'created_at',
              type: 'date-time',
            },
            {
              text: 'Created By',
              align: 'start',
              value: 'created_by_name',
              type: 'slot',
            },
            {
              value: 'action',
              type: 'action',
              actions: [
                {
                  key: 'on-item-edit',
                  label: 'Edit',
                  icon: 'mdi-pencil-box-outline',
                  color: 'success',
                },
                {
                  key: 'on-item-delete',
                  label: 'Delete',
                  icon: 'mdi-trash-can-outline',
                  color: 'danger',
                },
              ],
            },
            {
              text: 'Deleted Date',
              align: 'start',
              value: 'deleted_at',
              type: 'date-time',
            },
            {
              text: 'Deleted By',
              align: 'start',
              value: 'deleted_by_name',
              type: 'slot',
            },
          ]"
          @set-busy="setBusy"
          @on-item-edit="edit"
          @on-item-delete="remove"
        >
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key === 'account_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.account_id > 0">
                {{ row.prop.item.account_name }} ({{
                  row.prop.item.account_id
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === 'advertiser_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.advertiser_id > 0">
                {{ row.prop.item.advertiser_name }} ({{
                  row.prop.item.advertiser_id
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === 'created_by_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.created_by > 0">
                {{ row.prop.item.created_by_name }} ({{
                  row.prop.item.created_by
                }})
              </template>
            </div>
            <div
              v-if="row.prop.key === 'deleted_by_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.deleted_by > 0">
                {{ row.prop.item.deleted_by_name }} ({{
                  row.prop.item.deleted_by
                }})
              </template>
            </div>
          </template>
        </o-table>
      </v-card-text>

      <v-card-title v-if="action !== ''" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto">
          {{ `${action} - ${title}` }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="green"
            icon="mdi-content-save"
            type="label-icon"
            label="Save"
            @on-click="save()"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-keyboard-backspace"
            type="label-icon"
            label="Back"
            @on-click="action = ''"
          />
        </div>
      </v-card-title>

      <v-divider v-if="action !== ''" />

      <div v-if="action !== ''" class="ml-5 pb-5 mr-5">
        <v-row class="o-container-content">
          <v-col cols="12" class="pa-10 pr-15">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <o-input
                    v-if="action == 'edit'"
                    v-model="model.advertiser_id"
                    :busy="busy"
                    type="with-button"
                    label="Advertiser ID"
                    :rules="[(v) => !!v || 'This is required']"
                    @on-icon-click="saveAdvertiserId"
                  />
                </v-col>

                <v-col cols="12">
                  <o-input
                    v-model="model.advertiser_name"
                    :busy="busy"
                    label="Advertiser Name"
                    :is-required="true"
                    :rules="[(v) => !!v || 'This is required']"
                  />
                </v-col>

                <v-col cols="12" class="mt-0 pt-0">
                  <o-check-box
                    v-model="model.active"
                    :busy="busy"
                    label="Active"
                  />
                </v-col>

                <v-col cols="6">
                  <o-input
                    v-model="model.default_continent"
                    :busy="busy"
                    label="Default Continent"
                  />
                </v-col>

                <v-col cols="6">
                  <o-input
                    v-model="model.default_currency"
                    :busy="busy"
                    label="Default Currency"
                  />
                </v-col>

                <v-col cols="12">
                  <o-input
                    v-model="model.default_creative_thumbnail_url"
                    :busy="busy"
                    label="Default Creative Thumbnail"
                  />
                </v-col>

                <v-col cols="12">
                  <o-combo-box
                    v-model="model.account_id"
                    :busy="busy"
                    label="Account"
                    :items="accounts"
                  />
                </v-col>

                <v-col cols="12">
                  <o-input
                    v-model="model.advertiser_domain"
                    :busy="busy"
                    label="Domain"
                  />
                </v-col>

                <v-col cols="12">
                  <o-combo-box
                    v-model="model.advertiser_category"
                    :busy="busy"
                    label="Advertiser Category"
                    :items="advertiser_categories"
                  />
                </v-col>

                <v-col cols="12">
                  <o-input
                    v-model="model.default_click_url"
                    :busy="busy"
                    label="Click URL"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";

export default {
  name: "ManageAdvertiser",
  mixins: [RestApi],
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Advertisers",
  },
  data() {
    return {
      title: "Advertisers",
      accounts: [],
      advertiser_categories: [],
      account_id: 2,
      busy: false,
      action: "",
      valid: false,
      parameters: {
        advertiser_id: "", 
        advertiser_name: "",          
        account_id: [],
        active: []
      },
      baseModel: {
        id: 0,
        advertiser_name: "",
        conversion_method_id: 0,
        default_continent: "",
        default_creative_thumbnail_url: "",
        default_currency: "",
        account_id: 0,
        notes: "",
        active: false,
        advertiser_domain: "",
        advertiser_category: "",
        default_click_url: "",
        created_by: 0,
        updated_by: 0,
        advertiser_id: 0,
        manufacturer_id: 0,
        platform_id: 1,
      },
      model: {},
      originalModel: {},
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  created() {
    this.listParameters.fields = [
      "ROW_NUMBER() OVER(ORDER BY a.deleted_at DESC, a.updated_at DESC) row_no",
      "a.*",
      "b.account_name",
      "concat(c.last_name, ', ', c.first_name) as created_by_name",
      "concat(d.last_name, ', ', d.first_name) as deleted_by_name",
    ];
    this.listParameters.sources = {
      main: "advertisers a",
      children: [
        {
          name: "accounts b",
          on: "a.account_id = b.account_id",
        },
        {
          name: "users c",
          on: "a.created_by = c.id",
        },
        {
          name: "users d",
          on: "a.deleted_by = d.id",
        },
      ],
    };
    this.listParameters.orders = [
      {
        sort: "a.deleted_at",
        dir: "DESC",
      },
      {
        sort: "a.updated_at",
        dir: "DESC",
      },
    ];
  },
  async mounted() {
    this.accounts = await this.getList(
      ["account_id as id, account_name as name"],
      "accounts",
      [],
      [],
      "name"
    );
    this.advertiser_categories = await this.getList(
      ["id", "advertiser_category_name as name"],
      "advertiser_categories",
      [],
      [],
      "name"
    );
  },
  methods: {
    add() {
      this.action = "add";
      this.model = _.cloneDeep(this.baseModel);
      this.model.active = true;
      this.model.account_id = 2;
      this.model.default_continent = "NAM";
      this.modle.default_currency = "USD";
    },
    edit(item) {
      this.model = _.cloneDeep(item);
      this.model.platform_id = 1;
      this.originalModel = _.cloneDeep(item);
      this.action = "edit";
    },
    async remove(item) {
      this.model = item;
      await this.$refs.container
        .confirm(
          "Confirmation",
          `You are deleting <strong>${this.model.advertiser_name} (${this.model.advertiser_id})</strong>. Do you want to continue?`,
          "red"
        )
        .then(async (ans) => {
          if (ans) {
            this.model.deleted_by = this.loggedInUser.id;
            await this.deleteData(`/rest/advertisers/${this.model.id}`, null)
              .then((response) => {
                this.$refs.container.snackBar(
                  `
                The record of ${this.model.advertiser_name} (${this.model.advertiser_id}) was successfully deleted.
                `,
                  "red",
                  2000
                );
                this.reset();
              })
              .catch((err) => {
                this.$refs.container.snackBar(
                  `
                Error updating item: ${errors.join(",")}
                `,
                  "red",
                  2000
                );
              });
          }
        });
    },
    async saveAdvertiserId() {
      if (this.model.advertiser_id != this.originalModel.advertiser_id) {
        const par = _.cloneDeep(this.updateParameters);
        par.table = 'advertisers';
        par.fields = [{
          "field": "advertiser_id",
          "value": this.model.advertiser_id
        }]
        par.wheres = [{
          "field": "id",
          "value": this.model.id
        }]
        
        this.$refs.container.snackBar(
          `Advertiser ID was successful changed.`,
          "green",
          2000
        ); 
      } else {
        this.$refs.container.snackBar(
          `No change could be applied.`,
          "red",
          2000
        );
      }
    },
    async save() {
      if (!this.$refs.form.validate()) return false;
      const errors = [];
      const url = "/rest/advertisers";
      const model = _.cloneDeep(this.model);
      if (this.action === "add") {
        this.model.created_by = this.loggedInUser.id;
        await this.postData("/rest/advertisers", this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `
                Successfully added ${model.account_name} (${model.account_id}).
                `,
              "green",
              2000
            );
            this.reset();
          })          
          .catch((err) => {
            errors.push(err.data.message);
          });
      }
      if (this.action === "edit") {
        this.model.updated_by = this.loggedInUser.id;
        const model = _.cloneDeep(this.model);

        if (this.model.advertiser_name === this.originalModel.advertiser_name) {
          delete this.model.advertiser_name;
        }
        await this.putData(`/rest/advertisers/${this.model.id}`, this.model)
          .then((response) => {
            this.$refs.container.snackBar(
              `
                Successfully updated ${model.account_name} (${model.account_id}).
                `,
              "green",
              2000
            );
            this.reset();
          })
          .catch((err) => {
            if (err.status === 412) {
              this.$refs.container.snackBar(
                `Invalid object id combination, either one of the objects doesn't belong to another`,
                "red",
                2000
              );
            }
            errors.push(err.data.message);
          });
      }

      if (errors.length > 0) {
        this.$refs.container.snackBar(
          `
                Error updating item: ${errors.join(",")}
                `,
          "red",
          2000
        );
      } else {
        this.reset();
      }
    },
    reset(par) {
      if (par) {
        this.parameters = {
          advertiser_id: "", 
          advertiser_name: "",          
          active: [],
          account_id: [], 
        }
      }

      this.listParameters.wheres = [];
      const wheres = [];      
      if (this.parameters.advertiser_id != '') {
         wheres.push(`a.advertiser_id::text ilike '%${this.parameters.advertiser_id}%'`)
      }
      if (this.parameters.advertiser_name != '') {
         wheres.push(`a.advertiser_name ilike '%${this.parameters.advertiser_name}%'`)
      }
      if (this.parameters.account_id.length > 0) {
         wheres.push(`a.account_id in (${this.parameters.account_id.join(',')})`)
      }
      if (this.parameters.active.length > 0) {
         wheres.push(`a.active in (${this.parameters.active.join(',')})`)
      } 
      if (wheres.length > 0) this.listParameters.wheres = [wheres.join(' OR ')];

      this.action = "";
      this.model = this.baseModel;
      setTimeout(async () => {
        this.$refs.table.refresh();
      }, 200);
    },
    checkName(value) {
      let filter = `/findByName?account_name=${value}`;
      AccountsService.getResourceByFilter(filter)
        .then((response) => {
          this.errorExist = response.data ? "Name already exist" : "";
          this.rules.exist = !response.data;
        })
        .catch((err) => {
          console.log(err);
          this.snackbar_show = true;
          this.snackbar_message = "Error checking item: " + err;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.o-container-content {
  min-height: calc(100vh - 278px);
  max-height: calc(100vh - 278px);
  overflow-y: scroll;
}
</style>
