<template>
  <ManageAdvertiser dialog-name="Advertisers" />
</template>
<script>
import ManageAdvertiser from '@/views/app/manage/Advertiser'

export default {
  name: 'AdvertiserWrapper',
  components: {
    ManageAdvertiser
  }
}
</script>
